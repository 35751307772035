import React, { useEffect, useState } from 'react';
import { isMobile } from 'mobile-device-detect';
import axios from 'axios';
import { Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { PayPalButton } from 'react-paypal-button-v2';
import { DateSelect } from 'react-ymd-date-select/dist/cjs/presets/vanilla';
import Hashids from 'hashids/cjs';
import { config } from '../../settings';
import { canadianProvinces as provinces, usStates as states } from '../../utils/geographyUtils';
import PageWrap from '../../components/page-wrap';
import ModalDialog from '../../components/ModalDialog';
import './style.scss';
import cartActions from '../../utils/cart';
import * as Sentry from '@sentry/react';
import LoadingIndicator from '../../components/LoadingIndicator';
import { updateUser } from '../Auth/actions';
import exitIntent from '../../components/ExitIntent';
import { SettingsStore, ShopStore, UserStore } from '../../stores';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Product from '../../models/Product';
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import Icon from '../../components/Icon';
import { ReactComponent as CoinbaseLogo } from '../../../common-assets/images/coinbase-logo.svg';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import Badge from '@material-ui/core/Badge';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import { addressFromComponents } from '../VenueEditor';
import { Transducer } from '../../utils/transducer';
// import Switch from '@material-ui/core/Switch';
const hashids = new Hashids('', 12);

const { ApplePay, GooglePay } = window.Capacitor.Plugins;

let shippingRate = {};
let placingOrder = false;

function PaymentGateway(props) {
  return (
    <iframe
      ref={props.iframeRef}
      src={`${config.shopEndPoint}/forms/payments.html?theme=${props.darkMode ? 'dark' : 'light'}&buttonText=Place%20Order&userId=${props.userId || -1}&mode=${isMobile ? 'form' : 'card'}&collapsed=true&cartToken=${props.cartToken}`}
      style={{
        width: '100%',
        border: 'transparent',
        height: '100%',
      }}
    />
  );
}

function Checkout(props) {
  const darkMode = SettingsStore.useState((s) => s.darkMode);

  const cart = ShopStore.useState((s) => s.cart);
  const user = UserStore.useState((s) => s.user) || cart.user;
  const defaultBillingAddress = UserStore.useState((s) => s.defaultBillingAddress);
  const defaultShippingAddress = UserStore.useState((s) => s.defaultShippingAddress);

  const [activeStep, setActiveStep] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [submittedBilling, setSubmittedBilling] = useState(false);
  const [submittedShipping, setSubmittedShipping] = useState(false);
  const [fullShippingName, setFullShippingName] = useState(defaultShippingAddress.shippingName || null);
  const [email, setEmail] = useState(user?.email || null);
  const [userExists, setUserExists] = useState(false);
  const [billingName, setBillingName] = useState(defaultBillingAddress.billingName || null);
  const [billingAddress1, setBillingAddress1] = useState(defaultBillingAddress.address1 || null);
  const [billingAddress2, setBillingAddress2] = useState(defaultBillingAddress.address2 || null);
  const [billingCity, setBillingCity] = useState(defaultBillingAddress.city || null);
  const [billingState, setBillingState] = useState(defaultBillingAddress.state || null);
  const [billingZip, setBillingZip] = useState(defaultBillingAddress.zip || null);
  const [billingCountry, setBillingCountry] = useState(defaultBillingAddress.country || 'US');
  const [shippingName, setShippingName] = useState(defaultShippingAddress.shippingName || null); // FIXME Is this used? Seems like fullShippingName is the one used
  const [shippingAddress1, setShippingAddress1] = useState(defaultShippingAddress.address1 || null);
  const [shippingAddress2, setShippingAddress2] = useState(defaultShippingAddress.address2 || null);
  const [shippingCity, setShippingCity] = useState(defaultShippingAddress.city || null);
  const [shippingState, setShippingState] = useState(defaultShippingAddress.state || null);
  const [shippingZip, setShippingZip] = useState(defaultShippingAddress.zip || null);
  const [shippingCountry, setShippingCountry] = useState(defaultShippingAddress.country || 'US');
  const [phone, setPhone] = useState(user?.phone || null);
  const [birthdate, setBirthdate] = useState(user?.birthdate || null);
  const [loadingRates, setLoadingRates] = useState(true);
  const [shippingRates, setShippingRates] = useState(null);
  const [selectedRate, setSelectedRate] = useState(shippingRate);
  const [paymentInitialized, setPaymentInitialized] = useState(false);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);
  const [coinbaseChargeId, setCoinbaseChargeId] = useState(null);
  const [showShippingAddressEditor, setShowShippingAddressEditor] = useState(true);
  const [editBillingAddress, setEditBillingAddress] = useState(false);
  const [processingOrder, setProcessingOrder] = useState(false);
  const [userAddresses, setUserAddresses] = useState(null);
  const [loadingUserAddresses, setLoadingUserAddresses] = useState(true);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(defaultShippingAddress || null);
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState(null);
  const [submittedPayment, setSubmittedPayment] = useState(false);
  const [hasDefaults, setHasDefaults] = useState(false); // Maybe hasRequiredFields?
  const [ageVerified, setAgeVerified] = useState(false);
  const [saveCardToAccount, setSaveCardToAccount] = useState(true);
  const [isDefaultPaymentProfile, setDefaultPaymentProfile] = useState(true);

  const hasRequiredBillingFields = (submitted = true) => {
    setSubmittedBilling(submitted);
    console.log({
      billingName,
      email,
      billingAddress1,
      billingCity,
      billingZip,
      birthdate,
    });
    return (
      billingName && billingName.length
      && email && email.length
      && billingAddress1 && billingAddress1.length
      && billingCity && billingCity.length
      // && shippingState.length // TODO? This is a select
      && billingZip && billingZip.length
      // && shippingCountry.length // TODO? This is a select
      // && phone && phone.length // INFO Not sure this needs to be required right now
      && birthdate && birthdate.length
    );
  };

  const hasRequiredShippingFields = (submitted = true) => {
    setSubmittedShipping(submitted);
    console.log({
      fullShippingName,
      shippingAddress1,
      shippingCity,
      shippingZip,
      selectedRate,
    });
    return (
      fullShippingName && fullShippingName.length
      && shippingAddress1 && shippingAddress1.length
      && shippingCity && shippingCity.length
      // && shippingState.length // TODO? This is a select
      && shippingZip && shippingZip.length
      // && shippingCountry.length // TODO? This is a select
      && selectedRate && selectedRate.id
    );
  };

  const hasPaymentFields = (submitted = true) => {
    setSubmittedPayment(submitted);
    console.log('Checking payment fields...');
    if (selectedPaymentProfile) {
      return true;
    }
    return false;
  };

  const iframeRef = React.useRef();

  const handleCoinbaseMessage = (message) => {
    if (message.origin === 'https://commerce.coinbase.com') {
      if (message.data && message.data.buttonId && message.data.event === 'charge_confirmed') {
        if (!placingOrder) {
          placingOrder = true;
          placeOrder('crypto', message.data);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleCoinbaseMessage);
    return () => window.removeEventListener('message', handleCoinbaseMessage);
  }, []);

  useEffect(() => {
    window.addEventListener('message', (message) => {
      if (message.origin && message.origin.indexOf('boxpressd') !== -1) {
        console.log('iFrame message:');
        console.log(message);
      }
      if (message.data) {
        if (message.data.type === 'resize' && iframeRef.current) {
          iframeRef.current.style.height = `${message.data.height}px`;
        }
        if (message.data.type === 'payment_profile_selected') {
          setSelectedPaymentProfile(message.data.payment_profile);
        }
        if (message.data.type === 'payment_profiles_loaded') {
          // TODO Do something? Ensure that the selections and buttons are sync'd?
        }
        if (message.data.type === 'should_update_cart') {
          // TODO Pull the correct prices from the server and try again
        }
        if (message.data.type === 'payment_transaction' && message.data.success === true) {
          if (!placingOrder) {
            placingOrder = true;
            placeOrder('credit_card', { payment_token: message.data.payment_token });
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    setEmail((user && user.email) || cart.email || (cart.user && cart.user.email) || null);
    setPhone((user && user.phone) || cart.phone || (cart.user && cart.user.phone) || null);

    if (cart && cart.user) {
      if (cart.user.birthdate) {
        console.log('Birthday:');
        console.log(cart.user.birthdate);
        const dateParts = cart.user.birthdate.split('/');
        setBirthdate(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`);
      }
    }

    if (!defaultBillingAddress || Object.keys(defaultBillingAddress).length === 0) {
      if (cart.user && cart.user.default_billing_address) {
        UserStore.update((s) => {
          s.defaultBillingAddress = cart.user.default_billing_address;
        });
      }
    } else {
      if (defaultBillingAddress.first_name && defaultBillingAddress.last_name) {
        setBillingName(`${defaultBillingAddress.first_name} ${defaultBillingAddress.last_name}`);
      }
    }

    if (!defaultShippingAddress || Object.keys(defaultShippingAddress).length === 0) {
      if (cart.user && cart.user.default_shipping_address) {
        UserStore.update((s) => {
          s.defaultShippingAddress = cart.user.default_shipping_address;
        });
        setShippingName(`${cart.user.default_shipping_address.first_name} ${cart.user.default_shipping_address.last_name}`);
      } else {
        // Default to the same address as billing
        UserStore.update((s) => {
          s.defaultShippingAddress = defaultBillingAddress;
        });
        if (defaultBillingAddress.first_name && defaultBillingAddress.last_name) {
          setShippingName(`${defaultBillingAddress.first_name} ${defaultBillingAddress.last_name}`);
        }
      }
    } else {
      if (defaultShippingAddress.first_name && defaultShippingAddress.last_name) {
        setShippingName(`${defaultShippingAddress.first_name} ${defaultShippingAddress.last_name}`);
      }
    }

    if (cart.shipping_rate) {
      shippingRate = {
        ...cart.shipping_rate,
        name: cart.shipping_rate.shipping_method && cart.shipping_rate.shipping_method.name,
      };
    } else if (cart.user && cart.user.default_shipping_rate) {
      shippingRate = cart.user.default_shipping_rate;
    }
  }, []);

  useEffect(() => {
    // TODO I really this this can be removed - we're using OneSignal to handle this
    //  The way it works is when an item is added to a cart, it sets a flag on OneSignal. If a user completes an order,
    //  that flag is removed. If the flag still exists after 1 hour, the cart is considered abandoned and the user
    //  receives a push notification and/or an email so we never actually have to worry about it. The reason I wanted
    //  it in our database was so those abandoned carts can be shown in the business manager like Shopify offers
    //  I'd say we handle it the same way OneSignal does - abandoned carts are determined by the timestamp, not a status
    //  Completed orders get the cart status set to completed so it would be carts with status in progress and time
    //  difference of 1 hour or more
    const removeExitIntent = exitIntent({
      maxDisplays: 99999,
      eventThrottle: 100,
      triggerAfterInactiveSecondsDesktop: 8000, // FIXME Should this work this way? Setting it high for now so it doesn't trigger
      triggerAfterInactiveSecondsMobile: 8000,
      triggerAgainAfterSeconds: 300,
      onExitIntent: () => {
        console.log('Saving cart...');
        console.log(cart);
        // FIXME This isn't triggering
        // alert('Do you need help with your order?');
        if (cart.id) {
          if (window.analytics) {
            window.analytics.track('Abandoned Carts', { cart_id: cart.id });
          }
        }
        cartActions.update(cart, true);
        // INFO Seems like it won't wait for the await so the inner code wouldn't run in the "then" callback
        // await axios.put(`${config.shopEndPoint}/carts/${updatedCart.id}/abandoned`);
      },
    });
    return () => removeExitIntent();
  }, []);

  useEffect(() => {
    let step = 1;
    if (hasRequiredBillingFields(false)) {
      step = 2;
    }
    if (step === 2 && hasRequiredShippingFields(false)) {
      step = 3;
    }
    if (step === 3 && hasPaymentFields(false)) {
      setHasDefaults(true);
    }
    console.log('Step %d', step);
    setActiveStep(step);
  }, []);

  useEffect(() => {
    console.log('Current cart:');
    console.log(cart);
    if (cart && cart.token) {
      (async () => {
        // TODO Compare with local cart to show price change / stock info
        const updatedCart = await cartActions.get(cart.token);
        console.log('Cart updated:');
        console.log(updatedCart);
      })();
    }

    if (isMobile) {
      if (document.getElementById('mobile-search-bar')) {
        document.getElementById('mobile-search-bar').style.display = 'none';
      }
    }

    return () => {
      if (isMobile) {
        // FIXME Sometimes this fires after the componentWillMount from another component and shows it when it shouldn't
        if (document.getElementById('mobile-search-bar')) {
          document.getElementById('mobile-search-bar').style.display = 'flex';
        }
      }
    };
  }, []);

  useEffect(() => {
    if (user?.id) {
      console.log('User:');
      console.log(user);
      if (user.age_verified) {
        setAgeVerified(true);
      }
      if (!billingName || !billingName.length) {
        setBillingName(user.full_name ? user.full_name : `${user.first_name} ${user.last_name}`);
      }
      if (!shippingName || !shippingName.length) {
        setShippingName(user.full_name ? user.full_name : `${user.first_name} ${user.last_name}`);
        setFullShippingName(user.full_name ? user.full_name : `${user.first_name} ${user.last_name}`);
      }
      if (user.email && user.email.length) {
        setEmail(user.email);
      }
      if (user.phone && user.phone.length) {
        setPhone(user.phone);
      }
      if (user.birthdate) {
        console.log('Setting user birthdate:');
        // console.log(user);
        console.log(user.birthdate);
        const dateParts = user.birthdate.split('/');
        setBirthdate(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`);
      }
      // Grab their saved addresses
      if (!userAddresses) {
        axios.get(`${config.apiEndPoint}/users/${user.id}/addresses`).then((res) => {
          setLoadingUserAddresses(false);
          // TODO If the shipping and billing addresses aren't set, fill them in / default to the first (or last used) address
          //  and also create an array in state for the user to pick the address to use as a radio group
          const addresses = res.data;
          console.log('Users addresses:');
          console.log(addresses);
          if (addresses && addresses.length > 0) {
            setUserAddresses(addresses);
            setShowShippingAddressEditor(false); // INFO The form should only show if no addresses added or the user is adding a new one
            if (!defaultBillingAddress || Object.keys(defaultBillingAddress).length === 0) {
              addresses.forEach((address) => {
                if (address.billing) {
                  setBillingAddress1(address.address1 || null);
                  setBillingAddress2(address.address2 || null);
                  setBillingCity(address.city || null);
                  setBillingState(address.state || null);
                  setBillingCountry(address.country || 'US');
                  setBillingZip(address.zip || null);
                  // FIXME Why won't this consistently set the value??? Also, it can come from the user object if not on address
                  setPhone(address.phone || null);
                  UserStore.update((s) => {
                    s.defaultBillingAddress = address;
                  });
                  if (user.age_verified) {
                    setActiveStep(2);
                  }
                }
              });
            }
          }
        }).catch((err) => {
          setLoadingUserAddresses(false);
          console.log(err);
        });
      }
    } else {
      setLoadingUserAddresses(false);
    }
  }, [user]);

  useEffect(() => {
    if (defaultBillingAddress && Object.keys(defaultBillingAddress).length > 0) {
      console.log('Billing Address:');
      console.log(defaultBillingAddress);
      if (defaultBillingAddress.billingName) {
        setBillingName(defaultBillingAddress.billingName);
      } else if (defaultBillingAddress.first_name && defaultBillingAddress.last_name) {
        setBillingName(`${defaultBillingAddress.first_name} ${defaultBillingAddress.last_name}`);
      }
      // if (defaultBillingAddress.phone && defaultBillingAddress.phone.length) {
      //   setPhone(defaultBillingAddress.phone);
      // }
      if (!defaultShippingAddress || Object.keys(defaultShippingAddress).length === 0) {
        UserStore.update((s) => {
          s.defaultShippingAddress = defaultBillingAddress;
        });
      }
      cartActions.update({
        ...cart,
        billing_address: defaultBillingAddress,
      });
      Transducer.setPreference('default_billing_address', defaultBillingAddress);
    }
  }, [defaultBillingAddress]);

  useEffect(() => {
    if (defaultShippingAddress && Object.keys(defaultShippingAddress).length > 0) {
      console.log('Shipping Address:');
      console.log(defaultShippingAddress);
      if (defaultShippingAddress.billingName) {
        setShippingName(defaultShippingAddress.billingName);
      } else if (defaultShippingAddress.first_name && defaultShippingAddress.last_name) {
        setShippingName(`${defaultShippingAddress.first_name} ${defaultShippingAddress.last_name}`);
      }
      cartActions.update({
        ...cart,
        shipping_address: defaultShippingAddress,
      });
      Transducer.setPreference('default_shipping_address', defaultShippingAddress);
    }
  }, [defaultShippingAddress]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const chargeId = urlParams.get('charge_id');
    const paymentMethod = urlParams.get('payment_method');
    const cartToken = urlParams.get('cart_token');
    if (chargeId) {
      setCoinbaseChargeId(chargeId);
    }
    if (paymentMethod) {
      const method = { id: paymentMethod };
      setSelectedPaymentProfile(method);
      setTimeout(() => {
        // FIXME This needs to happen on load - see payment_profiles_loaded above
        iframeRef.current.contentWindow.postMessage({
          type: 'payment_profile_selected',
          payment_profile: method,
        }, '*');
      }, 2000);
    }
    if (cartToken) {
      // TODO Pull and update with cart details? What if they already have products in their cart? Ensure only 1 cart
      //  per logged in user and sync across all devices?
    }
  }, [window.location.search]);

  // FIXME Figure out why this state updates so often - user and activeStep should be minimizing the # of times it gets called, but doesn't seem to be
  useEffect(() => {
    // alert(activeStep);

    if (activeStep === 1 && !editBillingAddress) {
      if (!loadingUserAddresses) {
        if (ageVerified && hasRequiredBillingFields()) {
          setEditBillingAddress(false);
          setActiveStep(2);
          // console.log('Updating phone:');
          // console.log(phone);
          cartActions.update({
            ...cart,
            billing_address: {
              first_name: billingName.split(' ')[0],
              last_name: billingName.split(' ')[1],
              address1: billingAddress1,
              address2: billingAddress2,
              city: billingCity,
              state: billingState,
              country: billingCountry,
              zip: billingZip,
              phone,
              billing: true,
            },
          });
        }
      }
    }

    if (activeStep === 2 && !shippingRates) {
      getShippingRates();
    }

    // INFO This is now handled directly in the Boxpressd Payments form
    // if (activeStep === 3 && !paymentProfiles) {
    //   getPaymentProfiles();
    // }

    if (!paymentInitialized) {
      initializePaymentSession();
    }
  }, [
    user,
    activeStep,
    ageVerified,
    editBillingAddress,
    loadingUserAddresses,
  ]);

  useEffect(() => {
    try {
      cartActions.fetchShippingRates().then((response) => {
        console.log('Shipping Rates:');
        console.log(response);
      });
    } catch (error) {
      console.log(error);
    }
  }, [shippingZip, shippingCity, shippingState]);

  useEffect(() => {
    // TODO Limit to only if they exist? Has to be a non-null/undefined check since they can both be 0
    initializePaymentSession();
  }, [cart.shipping_rate, cart.tax_price]);

  const initializePaymentSession = () => {
    cartActions.initializePaymentSession()
      .then((paymentMethods) => {
        setPaymentInitialized(true);
        console.log('Payment methods');
        console.log(paymentMethods);
        setAvailablePaymentMethods(paymentMethods);
        // INFO Don't initialize until after taxes and shipping have been added
        // paymentMethods.forEach((method) => {
        //   if (method.id === 'crypto') {
        //     initializeCoinbaseButton();
        //   }
        //   if (method.id === 'google_pay') {
        //     initializeGooglePay();
        //   }
        // });
      }).catch((error) => {
        console.log('Unable to get payment methods:');
        console.log(error);
        setPaymentInitialized(true);
      });
  };

  const placeOrder = (gateway, data = {}) => {
    setProcessingOrder(true);
    // INFO For some reason, we don't get the latest cart without this extra step
    const latestCart = ShopStore.getRawState().cart;
    axios.post(`${config.shopEndPoint}/orders`, {
      ...latestCart,
      payment_gateway: gateway,
      payment_token: data.payment_token,
      paypal_order_id: data.orderID,
      authorization_id: data.authorizationID,
      coinbase_code_id: data.code,
      data_value: data.dataValue,
      data_descriptor: data.dataDescriptor,
      // FIXME I don't think these settings are used on the server. Let the user control whether to save cards to PCIVault
      save_payment_profile: selectedPaymentProfile && !selectedPaymentProfile.payment_profile_id && saveCardToAccount,
      set_as_default_payment_profile: selectedPaymentProfile && !selectedPaymentProfile.payment_profile_id && saveCardToAccount && isDefaultPaymentProfile,
      payment_profile_id: selectedPaymentProfile && selectedPaymentProfile.payment_profile_id,
    }).then(async (response) => {
      console.log('Order successfully placed');
      const order = response.data;
      console.log(order);

      try {
        if (window.analytics) {
          // FIXME This should break it down by seller (venue) when we allow multiple sellers on our platform
          window.analytics.track('Order Placed', {
            value: cart.total_price,
          });

          cart.items.forEach((item) => {
            window.analytics.track('Purchased Product', {
              name: Product.title(item.product),
              packaging: Product.packageTitle(item.product),
              product_id: item.product.id,
              venue_id: item.product.venue.id,
            });
          });
        }
      } catch (err) {
        Sentry.captureException(err);
      }

      setProcessingOrder(false);
      setSubmitting(false);

      try {
        window.goaffproTrackConversion({
          number: order.order_number,
          total: cart.total_price,
        });
      } catch (e) {
        Sentry.captureException(e);
      }

      props.history.push({
        // pathname: `/orders/${order.cart.token}`,
        pathname: `/orders/${hashids.encode(order.id)}`,
        search: `status=completed&order_number=${order.order_number}`,
      });
    }).catch((err) => {
      setProcessingOrder(false);
      setSubmitting(false);
      ModalDialog.show({
        title: 'Unable to process payment',
        message: 'We\'re sorry, but there was a problem creating this order.',
        buttons: [{
          label: 'Dismiss',
          onClick: () => {
            ModalDialog.close();
          },
        }],
      });
      console.error(err);
      Sentry.captureException(err);
    });
  };

  const initializePayPalButton = () => (
    <div
      style={{
        margin: '24px auto auto',
        maxWidth: 570,
      }}
    >
      <PayPalButton
        createOrder={(data, actions) => {
          let firstName;
          let lastName;
          if (billingName && billingName.length > 0) {
            const parts = billingName.split(' ');
            firstName = parts[0];
            lastName = parts[1];
          } else if (defaultBillingAddress && defaultBillingAddress.first_name && defaultBillingAddress.last_name) {
            firstName = defaultBillingAddress.first_name;
            lastName = defaultBillingAddress.last_name;
          }
          let firstShippingName;
          let lastShippingName;
          if (fullShippingName && fullShippingName.length > 0) {
            const parts = fullShippingName.split(' ');
            firstShippingName = parts[0];
            lastShippingName = parts[1];
          } else if (defaultShippingAddress && defaultShippingAddress.first_name && defaultShippingAddress.last_name) {
            firstShippingName = defaultShippingAddress.first_name;
            lastShippingName = defaultShippingAddress.last_name;
          }
          const order = {
            payer: {
              name: {
                given_name: firstName,
                surname: lastName,
              },
              address: {
                given_name: firstName,
                surname: lastName,
                address_line_1: billingAddress1,
                address_line_2: billingAddress2,
                admin_area_2: billingCity,
                admin_area_1: billingState,
                postal_code: billingZip,
                country_code: billingCountry,
              },
              email_address: email,
            },
            purchase_units: [
              {
                amount: {
                  value: cart.total_price,
                  currency_code: 'USD',
                },
                shipping: {
                  address: {
                    given_name: firstShippingName,
                    surname: lastShippingName,
                    address_line_1: shippingAddress1,
                    address_line_2: shippingAddress2,
                    admin_area_2: shippingCity,
                    admin_area_1: shippingState,
                    postal_code: shippingZip,
                    country_code: shippingCountry,
                  },
                },
              },
            ],
          };
          if (phone) {
            order.phone = {
              phone_type: 'MOBILE',
              phone_number: {
                national_number: phone,
              },
            };
          }
          console.log('PayPal order:');
          console.log(order);
          return actions.order.create(order);
        }}
        onApprove={(data, actions) => {
          console.log('PayPal approved data:');
          console.log(data);
          // This function authorizes the funds from the transaction so it can be verified and captured on our server.
          return actions.order.authorize().then((authorization) => {
            const authorizationID = authorization.purchase_units[0].payments.authorizations[0].id;
            console.log('PayPal authorization details:');
            console.log(authorization);
            placeOrder('paypal', {
              orderID: data.orderID,
              authorizationID,
            });
          });
        }}
        options={{
          clientId: config.paypal.client_id,
          intent: 'authorize',
        }}
      />
    </div>
  );

  const initializeCoinbaseButton = () => {
    // FIXME Not sure if we should rely on `coinbaseChargeId` - if the price changes, we need a new one to replace it,
    //  but won't get an updated one if this logic is in place to block it. Seems we would want to avoid it running on
    //  re-renders a different way. Consider putting initializeCoinbaseButton() and initializePayPalButton() in a
    //  useEffect instead
    if (!coinbaseChargeId) {
      // Force 10 cents when testing in dev - no way to fake transactions
      // axios.post(`${config.shopEndPoint}/payments/crypto/charges`, { ...cart, total_price: 0.1 }).then((response) => {
      axios.post(`${config.shopEndPoint}/payments/crypto/charges`, cart).then((response) => {
        if (response.data) {
          // alert('Got charge ID from server!');
          // INFO The chargeId is really the chargeCode - not sure why they named it incorrectly
          setCoinbaseChargeId(response.data.code);
        }
      }).catch((err) => {
        console.error(err);
        Sentry.captureException(err);
        // TODO Disable Coinbase button option?
      });
    }
  };

  // INFO Currently not supported
  // const initializeGooglePay = () => {
  //   GooglePay.initialize({ environment: env === 'development' ? 'TEST' : 'PRODUCTION' });
  // };

  const getShippingRates = () => {
    // TODO Send shipping address? For now it's ok since it's a flat rate, but if we move away from that, we would need the address
    axios.get(`${config.shopEndPoint}/shipping/methods`)
      .then((response) => {
        setShippingRates(response.data);
        setLoadingRates(false);
      })
      .catch((err) => {
        console.log(err);
        // TODO Error message in form?
        setLoadingRates(false);
      });
  };

  const getProductsByVenue = () => {
    const fulfillments = {};
    console.log('Cart items:');
    console.log(cart.items);
    cart.items.forEach((item) => {
      let products = [];
      if (fulfillments[item.product.venue_id]) {
        products = fulfillments[item.product.venue_id].products;
      } else {
        fulfillments[item.product.venue_id] = {};
      }
      products.push({
        ...item.product,
        quantity: item.quantity,
      });
      fulfillments[item.product.venue_id].venue = item.product.venue;
      fulfillments[item.product.venue_id].products = products;
    });
    console.log('Fulfillments:');
    console.log(fulfillments);
    return fulfillments;
  };

  const renderShippingRates = () => {
    if (loadingRates) {
      return <div>Loading shipping rates...</div>;
    }
    if (shippingRates) {
      console.log('Shipping rates:');
      console.log(shippingRates);
      return (
        <ul className="snipcart-shipping-rates-list" style={{ paddingLeft: 0 }}>
          {/* TODO Conditional snipcart-shipping-rates-list-item--highlight on <li> when selected */}
          {shippingRates.map((rate) => (
            <li
              className={`snipcart-shipping-rates-list-item snipcart__font--secondary snipcart__font--bold ${selectedRate.id === rate.id ? 'snipcart-shipping-rates-list-item--highlight' : ''} ${submittedShipping && !selectedRate.id ? 'snipcart-shipping-rates-list-item--error' : ''}`}
              data-value={rate.id}
              onClick={(e) => {
                // INFO Something else was triggering this, so e.preventDefault keeps it from running twice
                e.preventDefault();
                console.log('Selected new rate:');
                console.log(rate);
                setSelectedRate(rate);
                ShopStore.update((s) => {
                  s.cart.shipping_price = rate.price;
                  s.cart.shipping_rate = rate;
                });
              }}
            >
              <span>
                <span>
                  <input
                    type="radio"
                    id={`selectedRate-${rate.id}`}
                    name="selectedRate"
                    className="snipcart-form-radio"
                    value={rate.id}
                    checked={selectedRate.id === rate.id}
                  />
                  <label
                    htmlFor="selectedRate-ground"
                    className="snipcart-form__label snipcart-form-radio__label snipcart__font--std"
                  >
                    {rate.name}
                  </label>
                </span>
              </span>
              <span
                className="snipcart-shipping-rates-list-item--right snipcart-shipping-rates-list-item__price snipcart__font--black"
              >
                {`$${rate.price.toFixed(2)}`}
              </span>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const renderUserAddresses = () => {
    if (loadingUserAddresses) {
      return <div>Loading addresses...</div>;
    }
    if (userAddresses) {
      return (
        <ul
          className="snipcart-address-list"
          style={{
            paddingLeft: 0,
            margin: 0,
          }}
        >
          {userAddresses.map((address) => (
            <li
              className={`snipcart-address-list-item snipcart__font--secondary snipcart__font--bold ${selectedShippingAddress && (selectedShippingAddress.id === address.id) ? 'snipcart-address-list-item--highlight' : ''} ${submittedShipping && !selectedShippingAddress ? 'snipcart-shipping-rates-list-item--error' : ''}`}
              data-value={address.id}
              onClick={(e) => {
                e.preventDefault();
                setSelectedShippingAddress(address);
                setFullShippingName(address.first_name ? `${address.first_name} ${address.last_name}` : null);
                setShippingAddress1(address.address1 || null);
                setShippingAddress2(address.address2 || null);
                setShippingCity(address.city || null);
                setShippingState(address.state || null);
                setShippingCountry(address.country || 'US');
                setShippingZip(address.zip || null);
                UserStore.update((s) => {
                  s.defaultShippingAddress = address;
                });
              }}
            >
              <span>
                <span>
                  <input
                    type="radio"
                    id={`shippingAddress-${address.id}`}
                    name="shippingAddress"
                    className="snipcart-form-radio"
                    value={address.id}
                    checked={selectedShippingAddress && (selectedShippingAddress.id === address.id)}
                  />
                  <label
                    className="snipcart-form__label snipcart-form-radio__label snipcart__font--std"
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {`${address.first_name} ${address.last_name}
                      ${address.address1}${(address.address2 && address.address2.length) ? ` ${address.address2}` : ''}, ${address.city}, ${address.state}, ${address.country} ${address.zip}`}
                  </label>
                </span>
              </span>
              <span
                className="snipcart-shipping-rates-list-item--right snipcart-shipping-rates-list-item__price snipcart__font--black"
              >
                {'Choose'}
              </span>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const renderPaymentMethods = () => {
    // if (loadingPaymentOptions) {
    //   return <div>Loading payment options...</div>;
    // }
    if (availablePaymentMethods) {
      return (
        <ul
          className="snipcart-address-list"
          style={{
            paddingLeft: 0,
            margin: 0,
          }}
        >
          {availablePaymentMethods.map((method) => {
            if (method.id === 'credit_card') {
              return null;
            }
            return (
              <div>
                <li
                  className={`snipcart-address-list-item snipcart__font--secondary snipcart__font--bold ${selectedPaymentProfile && (selectedPaymentProfile.id === method.id) ? 'snipcart-address-list-item--highlight' : ''} ${submittedPayment && !selectedPaymentProfile ? 'snipcart-shipping-rates-list-item--error' : ''}`}
                  data-value={method.id}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedPaymentProfile(method);
                    console.log('Sending message to iframe...');
                    iframeRef.current.contentWindow.postMessage({
                      type: 'payment_profile_selected',
                      payment_profile: method,
                    }, '*');
                  }}
                >
                  <span>
                    <span>
                      <input
                        type="radio"
                        id={`paymentMethod-${method.id}`}
                        name="paymentMethod"
                        className="snipcart-form-radio"
                        value={method.id}
                        checked={selectedPaymentProfile && (selectedPaymentProfile.id === method.id)}
                      />
                      <label
                        className="snipcart-form__label snipcart-form-radio__label snipcart__font--std"
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {method.id === 'paypal'
                        && <Icon name={['fab', 'cc-paypal']} vendor="fa" style={{ marginRight: 12 }} />}
                        {method.id === 'credit_card'
                        && <Icon name={['fas', 'credit-card']} vendor="fa" style={{ marginRight: 12 }} />}
                        {
                          window.Capacitor.isNative
                          && window.Capacitor.platform === 'android'
                          && GooglePay.isReadyToPay()
                          && method.id === 'google_pay'
                          && <Icon name={['fab', 'google-pay']} vendor="fa" style={{ marginRight: 12 }} />
                        }
                        {
                          window.Capacitor.isNative
                          && window.Capacitor.platform === 'ios'
                          && ApplePay.canMakePayments()
                          && method.id === 'apple_pay'
                          && <Icon name={['fab', 'apple-pay']} vendor="fa" style={{ marginRight: 12 }} />
                        }
                        {!window.Capacitor.isNative && method.id === 'crypto'
                        && <Icon name={['fab', 'bitcoin']} vendor="fa" style={{ marginRight: 12 }} />}
                        {method.name}
                      </label>
                    </span>
                  </span>
                  <span
                    className="snipcart-shipping-rates-list-item--right snipcart-shipping-rates-list-item__price snipcart__font--black"
                  >
                    {/* {method.expiration || renderPaymentSecondary(method.secondary)} */}
                  </span>
                </li>
              </div>
            );
          })}
        </ul>
      );
    }
    return null;
  };

  const renderOrderButton = () => (
    <>
      <div>
        <PaymentGateway
          iframeRef={iframeRef}
          darkMode={darkMode}
          userId={cart.user_id || user?.id || -1}
          cartToken={cart.token}
        />
      </div>
      {selectedPaymentProfile && selectedPaymentProfile.id === 'paypal' && initializePayPalButton()}
      {/* FIXME The two below should just trigger when clicked on the radio option - no need for an extra button */}
      {/* /!* https://stackoverflow.com/questions/37818149/integrating-apple-pay-js-into-a-website *!/ */}
      {selectedPaymentProfile && selectedPaymentProfile.id === 'apple_pay' && window.ApplePaySession && window.ApplePaySession.canMakePayments() && (
        <div className="apple-pay-button apple-pay-button-black" />
      )}
      {/* /!* https://developers.google.com/pay/api/web/guides/brand-guidelines *!/ */}
      {/* https://github.com/google-pay/google-pay-button/tree/main/src/button-react#readme */}
      {/* {selectedPaymentProfile.id === 'google_pay' && window.GooglePaySession && window.GooglePaySession.canMakePayments() && ( */}
      {/*  <GooglePayButton */}
      {/*    environment={env === 'development' ? 'TEST' : 'PRODUCTION'} */}
      {/*    buttonColor="default" */}
      {/*    buttonType="buy" */}
      {/*    paymentRequest={{ */}
      {/*      apiVersion: 2, */}
      {/*      apiVersionMinor: 0, */}
      {/*      allowedPaymentMethods: [ */}
      {/*        { */}
      {/*          type: 'CARD', */}
      {/*          parameters: { */}
      {/*            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'], */}
      {/*            allowedCardNetworks: ['MASTERCARD', 'VISA'], */}
      {/*          }, */}
      {/*          tokenizationSpecification: { */}
      {/*            type: 'PAYMENT_GATEWAY', */}
      {/*            parameters: { */}
      {/*              gateway: 'example', */}
      {/*              gatewayMerchantId: 'exampleGatewayMerchantId', */}
      {/*            }, */}
      {/*          }, */}
      {/*        }, */}
      {/*      ], */}
      {/*      merchantInfo: { */}
      {/*        merchantId: '12345678901234567890', */}
      {/*        merchantName: 'Demo Merchant', */}
      {/*      }, */}
      {/*      transactionInfo: { */}
      {/*        totalPriceStatus: 'FINAL', */}
      {/*        totalPriceLabel: 'Total', */}
      {/*        totalPrice: '100.00', */}
      {/*        currencyCode: 'USD', */}
      {/*        countryCode: 'US', */}
      {/*      }, */}
      {/*    }} */}
      {/*  /> */}
      {/* )} */}
      {selectedPaymentProfile && selectedPaymentProfile.id === 'crypto' && initializeCoinbaseButton()}
      {availablePaymentMethods.length > 0 && selectedPaymentProfile && selectedPaymentProfile.id === 'crypto' && (
        <div style={{ textAlign: 'center' }}>
          {/* TODO Show loading until the call to /crypto/charges returns a chargeId */}
          {/* https://docs.cloud.coinbase.com/commerce/docs/add-payment-button */}
          <CoinbaseLogo
            style={{
              height: 30,
              width: 30,
              position: 'absolute',
              left: 'calc(50% - 90px)',
              marginTop: 24,
            }}
          />
          {window.Capacitor.isNative && (
            <button
              className="btn btn-coinbase"
              onClick={() => {
                // TODO Update the state to show an overlay similar to PayPal when redirecting to auth
                // FIXME This still doesn't work -- we need a completely different url that doesn't redirect back into the
                //  native app -- something like commerce.boxpressd.com/checkout
                // TODO Include the token to log the user in too?
                window.open(`https://bxpr.sd/checkout/web?cart_token=${cart.token}&payment_method=crypto&charge_id=${coinbaseChargeId}`, '_blank');
              }}
            >
              <svg
                baseProfile="tiny"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 1024 1024"
                overflow="visible"
                xmlSpace="preserve"
                style={{ height: 30, width: 30, marginTop: -4, marginRight: 10 }}
              >
                <path
                  fill="#FFFFFF"
                  d="M512,0L512,0c282.8,0,512,229.2,512,512l0,0c0,282.8-229.2,512-512,512l0,0C229.2,1024,0,794.8,0,512l0,0 C0,229.2,229.2,0,512,0z"
                >
                </path>
                <path
                  fill="#0062c6"
                  d="M512.1,692c-99.4,0-180-80.5-180-180s80.6-180,180-180c89.1,0,163.1,65,177.3,150h181.3 c-15.3-184.8-170-330-358.7-330c-198.8,0-360,161.2-360,360s161.2,360,360,360c188.7,0,343.4-145.2,358.7-330H689.3 C675,627,601.2,692,512.1,692z"
                >
                </path>
              </svg>
              {'Continue to Coinbase'}
            </button>
          )}
          {!window.Capacitor.isNative && (
            <CoinbaseCommerceButton
              style={{
                backgroundColor: '#0062c6',
                border: 'none',
                margin: '12px auto',
                color: 'white',
                borderRadius: 4,
                width: '100%',
                maxWidth: 570,
                height: 55,
              }}
              chargeId={coinbaseChargeId}
              // INFO This wasn't getting called so as a workaround, I intercept the 'charge_confirmed' event at the top of this file
              // onChargeSuccess={(messageData) => {
              //   Sentry.captureMessage(messageData);
              //   if (messageData.event === 'charge_confirmed') {
              //     placeOrder('crypto', messageData);
              //   }
              // }}
            />
          )}
          {/* TODO Show accepted crypto logos above? */}
        </div>
      )}
    </>
  );

  // FIXME This should work more like Amazon in the future - all orders are added, but they may be on hold if payment
  //  fails - in those cases, the user gets notified that they need to update their payment method. This is fine for now tho

  const fulfillments = getProductsByVenue();

  // FIXME Only show the form if there are items in the cart, otherwise show similar to empty cart layout or redirect to the cart
  // TODO  ^^^ This needs handled - it will crash if there are no items
  return (
    <PageWrap>
      <div style={{ marginTop: 40 }}>
        <div className="snipcart-checkout__content snipcart-layout__content">
          <div
            className="snipcart-layout__cols"
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <div
              style={{
                maxWidth: 728,
                width: '100%',
                margin: 'auto',
                flex: 1,
              }}
            >
              <div className="snipcart-layout__col snipcart-layout__col--large snipcart-footer">
                {/* TODO Login button if user is not already logged in? */}
                {hasDefaults && (
                  <div>
                    <p style={{ fontSize: 11 }}>
                      {'By placing your order, you agree to Boxpressd\'s '}
                      <a href="https://boxpressd.com/legal/privacy">privacy policy</a>
                      {' '}
                      {' and '}
                      <a href="https://boxpressd.com/legal/terms">terms of service</a>
                      {'.'}
                    </p>
                    {renderOrderButton()}
                  </div>
                )}
                <div className="snipcart-checkout-step">
                  <form className="snipcart-form">
                    <div className="root">
                      <form className="snipcart-form">
                        <div className="snipcart__box">
                          <div className="snipcart__box--header">
                            <div className="snipcart__box--title">
                              <div
                                className={`snipcart__box--badge snipcart__box--badge snipcart__font--bold snipcart__font--secondary ${activeStep === 1 ? 'snipcart__box--badge-highlight' : ''}`}
                              >
                                {activeStep > 1 ? (
                                  <svg
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    alt=""
                                    title=""
                                    className="snipcart__icon--large snipcart__icon"
                                  >
                                    <path
                                      d="M16.773 24.21l-4.554-4.472L11 20.933l5.773 5.667L29 14.595 27.781 13.4 16.773 24.21z"
                                      fill="url(#paint0_linear)"
                                    >
                                    </path>
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear"
                                        x1="11"
                                        y1="19.138"
                                        x2="29"
                                        y2="19.138"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#28a745"></stop>
                                        <stop offset="1" stopColor="#28a745"></stop>
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                ) : '1'}
                              </div>
                              <h1 className="snipcart__font--subtitle">
                                {'Billing'}
                              </h1>
                            </div>
                            {/* TODO Edit button should be visible only when user detail is set */}
                            {activeStep > 1 && (
                              <button
                                className="snipcart__actions--link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEditBillingAddress(true);
                                  setActiveStep(1);
                                }}
                              >
                                {'Change'}
                              </button>
                            )}
                          </div>

                          {activeStep === 1 && loadingUserAddresses && (
                            <div
                              style={{
                                textAlign: 'center',
                                padding: 20,
                              }}
                            >
                              <LoadingIndicator />
                            </div>
                          )}
                          {activeStep === 1 && !loadingUserAddresses && (
                            <div>
                              <fieldset className="snipcart-form__set">
                                <div className="snipcart-form__field">
                                  <label
                                    htmlFor="name"
                                    className="snipcart-form__label snipcart__font--tiny"
                                  >
                                    {'Full name'}
                                  </label>
                                  <div className="snipcart-input">
                                    <Input
                                      invalid={submittedBilling && (!billingName || billingName.length === 0)}
                                      id="name"
                                      type="text"
                                      name="name"
                                      value={billingName}
                                      onChange={(event) => setBillingName(event.target.value)}
                                      className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                    />
                                  </div>
                                </div>
                                <div className="snipcart-form__field">
                                  <label
                                    htmlFor="email"
                                    className="snipcart-form__label snipcart__font--tiny"
                                  >
                                    {'Email'}
                                  </label>
                                  <div className="snipcart-input">
                                    <Input
                                      // Prevents the user from changing their email to avoid malicious use
                                      disabled={user && user.email}
                                      invalid={submittedBilling && (!email || (!email || email.length === 0) || userExists)}
                                      id="email"
                                      type="text"
                                      name="email"
                                      value={email}
                                      onChange={(event) => {
                                        setEmail(event.target.value);
                                        cartActions.update({
                                          ...cart,
                                          email: event.target.value,
                                        });
                                      }}
                                      className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                    />
                                  </div>
                                  {userExists && (
                                    <div>
                                      <span style={{ color: '#ef5164', marginBottom: 20, display: 'inline-block' }}>
                                        {'This email address is already registered. Please login or use a different email address.'}
                                      </span>
                                      <button
                                        className="snipcart-cart-button snipcart-cart-button--highlight"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.location.href = `${config.authEndPoint}/login?path=${encodeURI(window.location.href)}`;
                                        }}
                                      >
                                        <span className="snipcart-cart-button__grid">
                                          <span className="snipcart-cart-button__content">
                                            {'Log In To Continue'}
                                          </span>
                                        </span>
                                      </button>
                                      <span>Please login to proceed. You will be redirected back to checkout.</span>
                                    </div>
                                  )}
                                  {/*<div style={{ display: 'flex', marginTop: 8 }}>*/}
                                  {/*  <Switch*/}
                                  {/*    edge="end"*/}
                                  {/*    onChange={(event) => {*/}
                                  {/*      // TODO Need a flag in the db to allow email offers*/}
                                  {/*    }}*/}
                                  {/*    checked={false}*/}
                                  {/*    inputProps={{ 'aria-labelledby': 'switch-list-label-allow-email-offers' }}*/}
                                  {/*  />*/}
                                  {/*  <span*/}
                                  {/*    style={{ marginLeft: 12 }}*/}
                                  {/*    onClick={() => {*/}
                                  {/*      // TODO Toggle the switch*/}
                                  {/*    }}*/}
                                  {/*  >*/}
                                  {/*    {'Send me offers via Email'}*/}
                                  {/*  </span>*/}
                                  {/*</div>*/}
                                </div>
                              </fieldset>
                              <div>
                                <fieldset className="snipcart-form__set">
                                  <div className="snipcart-form__row">
                                    <div className="snipcart-form__field snipcart-form__cell--large">
                                      <label
                                        htmlFor="address1"
                                        className="snipcart-form__label snipcart__font--tiny"
                                      >
                                        {'Street address'}
                                      </label>
                                      <div className="snipcart-input">
                                        <GooglePlacesAutocomplete
                                          // apiKey={config.google.api_key}
                                          style={{ width: '100%' }}
                                          className={`snipcart-input__input snipcart__font--secondary snipcart__font--bold form-control${submittedBilling && (!billingAddress1 || billingAddress1.length === 0) ? ' error-placeholder' : ''}`}
                                          placeholder=""
                                          value={billingAddress1}
                                          onChange={(e) => setBillingAddress1(e.target.value)}
                                          onPlaceSelected={(place) => {
                                            // TODO Update remaining fieds
                                            console.log('Selected place:');
                                            console.log(place);
                                            const comp = place.address_components;
                                            const address = addressFromComponents(comp);
                                            console.log(address);
                                            setBillingAddress1(address.address1);
                                            // FIXME addressFromComponents doesn't include address2
                                            setBillingCity(address.city);
                                            setBillingState(address.state);
                                            // setBillingCountry(address.country); // FIXME Not going to work - this needs to be the 2 char abbrev
                                            setBillingZip(address.zip);
                                          }}
                                          types={['address']}
                                          fields={[
                                            'address_components',
                                            'formatted_address',
                                          ]}
                                        />
                                      </div>
                                    </div>
                                    <div className="snipcart-form__field snipcart-form__cell--tidy">
                                      <label
                                        htmlFor="address2"
                                        className="snipcart-form__label snipcart__font--tiny"
                                      >
                                        {'Apt/Suite'}
                                      </label>
                                      <div className="snipcart-input">
                                        <Input
                                          id="address2"
                                          type="text"
                                          name="address2"
                                          value={billingAddress2}
                                          onChange={(event) => setBillingAddress2(event.target.value)}
                                          className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="snipcart-form__field">
                                    <label
                                      htmlFor="city"
                                      className="snipcart-form__label snipcart__font--tiny"
                                    >
                                      {'City'}
                                    </label>
                                    <div className="snipcart-input">
                                      <Input
                                        invalid={submittedBilling && (!billingCity || billingCity.length === 0)}
                                        id="city"
                                        type="text"
                                        name="city"
                                        value={billingCity}
                                        onChange={(event) => setBillingCity(event.target.value)}
                                        className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                      />
                                    </div>
                                  </div>
                                  <div className="snipcart-form__field">
                                    <label
                                      htmlFor="country"
                                      className="snipcart-form__label snipcart__font--tiny"
                                    >
                                      {'Country'}
                                    </label>
                                    <div className="snipcart-typeahead">
                                      <div className="snipcart-typeahead__content">
                                        <select
                                          autoComplete="prevent-autofill"
                                          className="snipcart-form__select snipcart__font--secondary snipcart__font--bold"
                                          value={billingCountry}
                                          onChange={(event) => setBillingCountry(event.target.value)}
                                        >
                                          <option value="US">
                                            United States
                                          </option>
                                          {/* <option value="CA"> */}
                                          {/*  Canada */}
                                          {/* </option> */}
                                        </select>
                                        <select
                                          name="country-target"
                                          autoComplete="country"
                                          tabIndex="-1"
                                          className="snipcart-typeahead-autocomplete"
                                        >
                                          <option value="US">
                                            United States
                                          </option>
                                          {/* <option value="CA"> */}
                                          {/*  Canada */}
                                          {/* </option> */}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="snipcart-form__row">
                                    <div className="snipcart-form__field snipcart-form__cell--large">
                                      <label
                                        htmlFor="province"
                                        className="snipcart-form__label snipcart__font--tiny"
                                      >
                                        {'Province/State'}
                                      </label>
                                      <div className="snipcart-typeahead">
                                        <div className="snipcart-typeahead__content">
                                          <select
                                            autoComplete="prevent-autofill"
                                            className="snipcart-form__select snipcart__font--secondary snipcart__font--bold"
                                            value={billingState}
                                            onChange={(event) => setBillingState(event.target.value)}
                                          >
                                            {billingCountry === 'US' && states.map((usState) => (
                                              <option value={usState.abbreviation}>{usState.name}</option>
                                            ))}
                                            {billingCountry === 'CA' && provinces.map((province) => (
                                              <option value={province.abbreviation}>{province.name}</option>
                                            ))}
                                          </select>
                                          <select
                                            name="province-target"
                                            autoComplete="province state"
                                            tabIndex="-1"
                                            className="snipcart-typeahead-autocomplete"
                                          >
                                            {billingCountry === 'US' && states.map((usState) => (
                                              <option value={usState.abbreviation}>{usState.name}</option>
                                            ))}
                                            {billingCountry === 'CA' && provinces.map((province) => (
                                              <option value={province.abbreviation}>{province.name}</option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="snipcart-form__field snipcart-form__cell--tidy">
                                      <label
                                        htmlFor="postalCode"
                                        className="snipcart-form__label snipcart__font--tiny"
                                      >
                                        {'Postal/ZIP code'}
                                      </label>
                                      <div className="snipcart-input">
                                        <Input
                                          invalid={submittedBilling && (!billingZip || billingZip.length === 0)}
                                          id="postalCode"
                                          type="text"
                                          name="postalCode"
                                          value={billingZip}
                                          onChange={(event) => setBillingZip(event.target.value)}
                                          className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                              <hr className="snipcart-form__separator" />
                              {/* INFO For now, just let them change the shipping address below */}
                              <fieldset className="snipcart-form__set" style={{ display: 'none' }}>
                                <div className="snipcart-form__field">
                                  <div className="snipcart-form__field-checkbox">
                                    <input
                                      type="checkbox"
                                      name="useDifferentShippingAddress"
                                      id="useDifferentShippingAddress"
                                      className="snipcart-checkbox"
                                    />
                                  </div>
                                </div>
                              </fieldset>
                              <fieldset className="snipcart-form__set">
                                <div className="snipcart-form__field">
                                  <label
                                    htmlFor="phone"
                                    className="snipcart-form__label snipcart__font--tiny"
                                  >
                                    {'Phone'}
                                  </label>
                                  <div className="snipcart-input">
                                    {/* FIXME Require for all states or only the ones we need for M&D? */}
                                    <Input
                                      // invalid={submittedBilling && (!phone || phone.length === 0)}
                                      id="phone"
                                      type="text"
                                      name="phone"
                                      value={phone}
                                      onChange={(event) => {
                                        setPhone(event.target.value);
                                        cartActions.update({
                                          ...cart,
                                          phone: event.target.value,
                                        });
                                      }}
                                      className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                    />
                                  </div>
                                  {/*<div style={{ display: 'flex', marginTop: 8 }}>*/}
                                  {/*  <Switch*/}
                                  {/*    edge="end"*/}
                                  {/*    onChange={(event) => {*/}
                                  {/*      // TODO Need a flag in the db to allow SMS offers via Cigar Text concept*/}
                                  {/*    }}*/}
                                  {/*    checked={false}*/}
                                  {/*    inputProps={{ 'aria-labelledby': 'switch-list-label-facebook' }}*/}
                                  {/*  />*/}
                                  {/*  <span*/}
                                  {/*    style={{ marginLeft: 12 }}*/}
                                  {/*    onClick={() => {*/}
                                  {/*      // TODO Toggle the switch*/}
                                  {/*    }}*/}
                                  {/*  >*/}
                                  {/*    {'Send me offers via SMS (messaging rates apply)'}*/}
                                  {/*  </span>*/}
                                  {/*</div>*/}
                                </div>
                                <label
                                  htmlFor="birthday"
                                  className="snipcart-form__label snipcart__font--tiny"
                                  style={{ color: submittedBilling && (!birthdate || !birthdate.length) ? '#ef5164' : 'inherit' }}
                                >
                                  {'Date of Birth'}
                                </label>
                                <div className={`snipcart-form__birthdate${submittedBilling && (!birthdate || !birthdate.length) ? ' invalid' : ''}`}>
                                  <DateSelect
                                    monthFormat="MMMM"
                                    dayFormat="dd"
                                    firstYear={new Date().getFullYear() - 20}
                                    lastYear={new Date().getFullYear() - 110}
                                    value={birthdate || (new Date()).toISOString()}
                                    onChange={(date) => {
                                      setBirthdate(date);
                                      updateUser({
                                        ...user,
                                        birthdate: date,
                                      });
                                    }}
                                    className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                    style={{ borderColor: submittedBilling && (!birthdate || !birthdate.length) ? '#ef5164' : 'inherit' }}
                                  />
                                </div>
                              </fieldset>
                              <div className="snipcart-form__footer">
                                <button
                                  type="submit"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    if (!submitting) {
                                      setSubmitting(true);
                                      const billingAddress = {
                                        first_name: billingName && billingName.split(' ')[0].trim(),
                                        last_name: billingName && billingName.split(' ')[1].trim(),
                                        address1: billingAddress1 && billingAddress1.trim(),
                                        address2: billingAddress2 && billingAddress2.trim(),
                                        city: billingCity && billingCity.trim(),
                                        state: billingState && billingState.trim(),
                                        country: billingCountry && billingCountry.trim(),
                                        zip: billingZip && billingZip.trim(),
                                        phone,
                                        billing: true,
                                        is_default: true,
                                      };
                                      console.log('Setting Billing address:');
                                      console.log(billingAddress);
                                      if ((!userAddresses || userAddresses.length === 0) && (!defaultShippingAddress || Object.keys(defaultShippingAddress).length === 0)) {
                                        console.log('Copying billing address to shipping...');
                                        // setSelectedShippingAddress(billingAddress);
                                        setFullShippingName(billingName);
                                        setShippingAddress1(billingAddress1);
                                        setShippingAddress2(billingAddress2);
                                        setShippingCity(billingCity);
                                        setShippingState(billingState);
                                        setShippingCountry(billingCountry);
                                        setShippingZip(billingZip);
                                        UserStore.update((s) => {
                                          s.defaultShippingAddress = billingAddress;
                                        });
                                      } else if (defaultShippingAddress) {
                                        if (defaultShippingAddress.firstName && defaultShippingAddress.lastName) {
                                          setFullShippingName(`${defaultShippingAddress.firstName} ${defaultShippingAddress.lastName}`);
                                        }
                                        setShippingAddress1(defaultShippingAddress.address1);
                                        setShippingAddress2(defaultShippingAddress.address2);
                                        setShippingCity(defaultShippingAddress.city);
                                        setShippingState(defaultShippingAddress.state);
                                        setShippingCountry(defaultShippingAddress.country);
                                        setShippingZip(defaultShippingAddress.zip);
                                      }
                                      console.log('Verifying customer age with AgeChecker...');
                                      if (hasRequiredBillingFields()) {
                                        console.log('Passing billing data to AgeChecker...');
                                        const dateParts = birthdate.split('-');
                                        axios.post(`${config.shopEndPoint}/agechecker/verify`, {
                                          first_name: billingName ? billingName.split(' ')[0] : user.first_name,
                                          last_name: billingName ? billingName.split(' ')[1] : user.last_name,
                                          email,
                                          address1: billingAddress1,
                                          address2: billingAddress2,
                                          city: billingCity,
                                          state: billingState,
                                          zip: billingZip,
                                          country: billingCountry,
                                          // TODO Make sure DOB is stored to the user (db and locally) - they shouldn't have to enter it every time
                                          dob_day: dateParts[2],
                                          dob_month: dateParts[1],
                                          dob_year: dateParts[0],
                                        }).then((res) => {
                                          if (!res.data.created && (!user || !user.id)) {
                                            // If they're logged out and already have an account, prompt them to log in
                                            setUserExists(true);
                                            setSubmitting(false);
                                            setTimeout(() => {
                                              window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }, 150);
                                          } else {
                                            try {
                                              console.log('AgeChecker user response:');
                                              console.log(res.data);
                                              if (res.status === 200) {
                                                UserStore.update((s) => {
                                                  s.defaultBillingAddress = billingAddress;
                                                });
                                                cartActions.update({
                                                  ...cart,
                                                  user: res.data,
                                                  // INFO This needs to get linked after a user is associated
                                                  billing_address: billingAddress,
                                                }).then((response) => {
                                                  setSubmitting(false);
                                                  console.log(response);
                                                  console.log('Success! Moving on...');
                                                  setActiveStep(2);
                                                }).catch((error) => {
                                                  setSubmitting(false);
                                                  console.log(error);
                                                });
                                              } else if (res.status === 202) {
                                                setSubmitting(false);
                                                if (window.AgeCheckerAPI) {
                                                  const AgeChecker = window.AgeCheckerAPI.createInstance({
                                                    mode: 'manual',
                                                    key: 'PwRdOhZJtnsJ5eWZilatZWG9nQBDw7Us',
                                                    accent_color: '#d5c196',
                                                    input_focus_color: '#d5c196',
                                                    logo_url: 'https://boxpressd.com/0e769698a4adfe9c7ac2b0e6fce875b3.png',
                                                    data: {
                                                      address: billingAddress1,
                                                      zip: billingZip,
                                                      first_name: billingName.split(' ')[0],
                                                      last_name: billingName.split(' ')[1],
                                                      country: billingCountry,
                                                      state: billingState,
                                                      city: billingCity,
                                                      dob_day: dateParts[2],
                                                      dob_month: dateParts[1],
                                                      dob_year: dateParts[0],
                                                    },
                                                  });
                                                  AgeChecker.show(res.data.agechecker_uuid);
                                                }
                                              }
                                            } catch (e) {
                                              // INFO If there error happen within this block, it shouldn't trigger
                                              //  AgeChecker so we catch it to avoid the wrong flow
                                              Sentry.captureException(e);
                                            }
                                          }
                                        }).catch((err) => {
                                          console.log('AgeCheckerAPI error');
                                          console.log(err);
                                          setSubmitting(false);
                                          if (window.AgeCheckerAPI) {
                                            const AgeChecker = window.AgeCheckerAPI.createInstance({
                                              mode: 'manual',
                                              key: 'PwRdOhZJtnsJ5eWZilatZWG9nQBDw7Us',
                                              accent_color: '#d5c196',
                                              input_focus_color: '#d5c196',
                                              logo_url: 'https://boxpressd.com/0e769698a4adfe9c7ac2b0e6fce875b3.png',
                                              data: {
                                                address: billingAddress1,
                                                zip: billingZip,
                                                first_name: billingName.split(' ')[0],
                                                last_name: billingName.split(' ')[1],
                                                country: billingCountry,
                                                state: billingState,
                                                city: billingCity,
                                                dob_day: dateParts[2],
                                                dob_month: dateParts[1],
                                                dob_year: dateParts[0],
                                              },
                                            });
                                            AgeChecker.show();
                                          }
                                          Sentry.captureException(err);
                                        });
                                      } else {
                                        console.log('Waiting on fields...');
                                        setSubmitting(false);
                                      }
                                    }
                                  }}
                                  className={`snipcart-cart-button snipcart__font--bold snipcart__font--secondary boxpressd-checkout-billing-continue snipcart-cart-button--medium snipcart-cart-button--highlight snipcart__font--large ${submitting && 'disabled'}`}
                                  style={{
                                    marginTop: 20,
                                    marginBottom: 0,
                                  }}
                                >
                                  <span className="snipcart-cart-button__grid">
                                    <span className="snipcart-cart-button__content">
                                      {submitting
                                      && <i className="fa fa-spin fa-circle-notch" style={{ marginRight: 10 }} />}
                                      {'Continue to shipping'}
                                    </span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          )}

                          {activeStep !== 1 && defaultBillingAddress && (
                            <div>
                              <div>
                                <div>{billingName}</div>
                                <div>{email}</div>
                              </div>
                              <div>
                                {`${billingAddress1}${(billingAddress2 && billingAddress2.length) ? ` ${billingAddress2}` : ''}, ${billingCity}, ${billingState}, ${billingCountry} ${billingZip}`}
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </form>
                </div>

                <div className="snipcart-checkout-step">
                  <form className="snipcart-form">
                    <div className={`snipcart__box ${activeStep < 2 ? 'snipcart__box--gray snipcart__box--slim' : ''}`}>
                      <div className="snipcart__box--header">
                        <div className="snipcart__box--title">
                          <div
                            className={`snipcart__box--badge snipcart__box--badge snipcart__font--bold snipcart__font--secondary ${activeStep === 2 ? 'snipcart__box--badge-highlight' : ''}`}
                          >
                            {activeStep > 2 ? (
                              <svg
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                alt=""
                                title=""
                                className="snipcart__icon--large snipcart__icon"
                              >
                                <path
                                  d="M16.773 24.21l-4.554-4.472L11 20.933l5.773 5.667L29 14.595 27.781 13.4 16.773 24.21z"
                                  fill="url(#paint0_linear)"
                                >
                                </path>
                                <defs>
                                  <linearGradient
                                    id="paint0_linear"
                                    x1="11"
                                    y1="19.138"
                                    x2="29"
                                    y2="19.138"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#28a745"></stop>
                                    <stop offset="1" stopColor="#28a745"></stop>
                                  </linearGradient>
                                </defs>
                              </svg>
                            ) : '2'}
                          </div>
                          <h1 className="snipcart__font--subtitle">Shipping</h1>
                        </div>
                        {activeStep >= 2 && !showShippingAddressEditor && (
                          <button
                            className="snipcart__actions--link"
                            onClick={(e) => {
                              e.preventDefault();
                              if (activeStep !== 2) {
                                setActiveStep(2);
                                setShowShippingAddressEditor(false);
                              }
                            }}
                          >
                            {'Change'}
                          </button>
                        )}
                      </div>

                      {activeStep === 2 && (
                        <div>
                          <div className="snipcart-shipping-address--readonly">
                            <div className="snipcart-shipping-address__header--readonly">
                              <h3
                                className="snipcart-shipping-address__subtitle"
                              >
                                {'Shipping to:'}
                              </h3>
                            </div>
                            {renderUserAddresses()}
                            {userAddresses && userAddresses.length > 0 && (
                              <button
                                className="snipcart__actions--link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowShippingAddressEditor(true);
                                }}
                              >
                                {'+ Add a New Address'}
                              </button>
                            )}
                            {showShippingAddressEditor && (
                              <div>
                                {/* TODO Add "Same as billing" toggle */}
                                <fieldset className="snipcart-form__set">
                                  <div className="snipcart-form__field">
                                    <label
                                      htmlFor="shippingname"
                                      className="snipcart-form__label snipcart__font--tiny"
                                    >
                                      {'Full name'}
                                    </label>
                                    <div className="snipcart-input">
                                      <Input
                                        invalid={submittedShipping && (!fullShippingName || fullShippingName.length === 0)}
                                        id="shippingname"
                                        type="text"
                                        name="name"
                                        value={fullShippingName}
                                        onChange={(event) => setFullShippingName(event.target.value)}
                                        className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                      />
                                    </div>
                                  </div>
                                  <div className="snipcart-form__row">
                                    <div className="snipcart-form__field snipcart-form__cell--large">
                                      <label
                                        htmlFor="shippingaddress1"
                                        className="snipcart-form__label snipcart__font--tiny"
                                      >
                                        {'Street address'}
                                      </label>
                                      <div className="snipcart-input">
                                        <GooglePlacesAutocomplete
                                          // apiKey={config.google.api_key}
                                          style={{ width: '100%' }}
                                          className={`snipcart-input__input snipcart__font--secondary snipcart__font--bold form-control${submittedShipping && (!shippingAddress1 || shippingAddress1.length === 0) ? ' error-placeholder' : ''}`}
                                          placeholder=""
                                          value={shippingAddress1}
                                          onChange={(e) => setShippingAddress1(e.target.value)}
                                          onPlaceSelected={(place) => {
                                            console.log('Selected place:');
                                            console.log(place);
                                            const comp = place.address_components;
                                            const address = addressFromComponents(comp);
                                            console.log(address);
                                            setShippingAddress1(address.address1);
                                            // FIXME addressFromComponents doesn't include address2
                                            setShippingCity(address.city);
                                            setShippingState(address.state);
                                            // setShippingCountry(address.country); // FIXME Not going to work - this needs to be the 2 char abbrev
                                            setShippingZip(address.zip);
                                          }}
                                          types={['address']}
                                          fields={[
                                            'address_components',
                                            'formatted_address',
                                          ]}
                                        />
                                      </div>
                                    </div>
                                    <div className="snipcart-form__field snipcart-form__cell--tidy">
                                      <label
                                        htmlFor="shippingaddress2"
                                        className="snipcart-form__label snipcart__font--tiny"
                                      >
                                        {'Apt/Suite'}
                                      </label>
                                      <div className="snipcart-input">
                                        <Input
                                          id="shippingaddress2"
                                          type="text"
                                          name="address2"
                                          value={shippingAddress2}
                                          onChange={(event) => setShippingAddress2(event.target.value)}
                                          className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="snipcart-form__field">
                                    <label
                                      htmlFor="shippingcity"
                                      className="snipcart-form__label snipcart__font--tiny"
                                    >
                                      {'City'}
                                    </label>
                                    <div className="snipcart-input">
                                      <Input
                                        invalid={submittedShipping && (!shippingCity || shippingCity.length === 0)}
                                        id="shippingcity"
                                        type="text"
                                        name="city"
                                        value={shippingCity}
                                        onChange={(event) => setShippingCity(event.target.value)}
                                        className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                      />
                                    </div>
                                  </div>
                                  <div className="snipcart-form__field">
                                    <label
                                      htmlFor="shippingcountry"
                                      className="snipcart-form__label snipcart__font--tiny"
                                    >
                                      {'Country'}
                                    </label>
                                    <div className="snipcart-typeahead">
                                      <div className="snipcart-typeahead__content">
                                        <select
                                          autoComplete="prevent-autofill"
                                          className="snipcart-form__select snipcart__font--secondary snipcart__font--bold"
                                          value={shippingCountry}
                                          onChange={(event) => setShippingCountry(event.target.value)}
                                        >
                                          <option value="US">
                                            United States
                                          </option>
                                          {/* <option value="CA"> */}
                                          {/*  Canada */}
                                          {/* </option> */}
                                        </select>
                                        <select
                                          name="country-target"
                                          autoComplete="country"
                                          tabIndex="-1"
                                          className="snipcart-typeahead-autocomplete"
                                        >
                                          <option value="US">
                                            United States
                                          </option>
                                          {/* <option value="CA"> */}
                                          {/*  Canada */}
                                          {/* </option> */}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="snipcart-form__row">
                                    <div className="snipcart-form__field snipcart-form__cell--large">
                                      <label
                                        htmlFor="shippingprovince"
                                        className="snipcart-form__label snipcart__font--tiny"
                                      >
                                        {'Province/State'}
                                      </label>
                                      <div className="snipcart-typeahead">
                                        <div className="snipcart-typeahead__content">
                                          <select
                                            autoComplete="prevent-autofill"
                                            className="snipcart-form__select snipcart__font--secondary snipcart__font--bold"
                                            value={shippingState}
                                            onChange={(event) => setShippingState(event.target.value)}
                                          >
                                            {shippingCountry === 'US' && states.map((usState) => (
                                              <option value={usState.abbreviation}>{usState.name}</option>
                                            ))}
                                            {shippingCountry === 'CA' && provinces.map((province) => (
                                              <option value={province.abbreviation}>{province.name}</option>
                                            ))}
                                          </select>
                                          <select
                                            name="province-target"
                                            autoComplete="province state"
                                            tabIndex="-1"
                                            className="snipcart-typeahead-autocomplete"
                                          >
                                            {shippingCountry === 'US' && states.map((usState) => (
                                              <option value={usState.abbreviation}>{usState.name}</option>
                                            ))}
                                            {shippingCountry === 'CA' && provinces.map((province) => (
                                              <option value={province.abbreviation}>{province.name}</option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="snipcart-form__field snipcart-form__cell--tidy">
                                      <label
                                        htmlFor="shippingpostalCode"
                                        className="snipcart-form__label snipcart__font--tiny"
                                      >
                                        {'Postal/ZIP code'}
                                      </label>
                                      <div className="snipcart-input">
                                        <Input
                                          invalid={submittedShipping && (!shippingZip || shippingZip.length === 0)}
                                          id="shippingpostalCode"
                                          type="text"
                                          name="postalCode"
                                          value={shippingZip}
                                          onChange={(event) => setShippingZip(event.target.value)}
                                          className="snipcart-input__input snipcart__font--secondary snipcart__font--bold"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                                <hr className="snipcart-form__separator" style={{ marginBottom: 25 }} />
                              </div>
                            )}
                          </div>
                          {/* FIXME Only render rates after the shipping info has been entered? Also should break the shipments up by vendor so we would have multiple rates */}
                          <div className="snipcart-shipping-address__header--readonly">
                            <h3
                              className="snipcart-shipping-address__subtitle"
                            >
                              {'Shipping method:'}
                            </h3>
                          </div>
                          {renderShippingRates()}
                          <div className="snipcart-form__footer">
                            <button
                              type="submit"
                              className={`snipcart-cart-button snipcart__font--bold snipcart__font--secondary snipcart-cart-button--medium snipcart-cart-button--highlight snipcart__font--large ${submitting && 'disabled'}`}
                              onClick={(e) => {
                                e.preventDefault();
                                if (hasRequiredShippingFields()) {
                                  setSubmitting(true);
                                  console.log('Selected Rate:');
                                  console.log(selectedRate);
                                  cartActions.update({
                                    ...cart,
                                    shipping_rate: selectedRate,
                                    shipping_price: selectedRate.price,
                                    shipping_address: {
                                      first_name: fullShippingName.split(' ')[0].trim(),
                                      last_name: fullShippingName.split(' ')[1].trim(),
                                      address1: shippingAddress1 ? shippingAddress1.trim() : null,
                                      address2: shippingAddress2 ? shippingAddress2.trim() : null,
                                      city: shippingCity ? shippingCity.trim() : null,
                                      state: shippingState ? shippingState.trim() : null,
                                      country: shippingCountry ? shippingCountry.trim() : null,
                                      zip: shippingZip ? shippingZip.trim() : null,
                                      phone,
                                      is_default: true, // TODO This should eventually allow the user to select - this will have it default to the last selection
                                    },
                                  }).then(() => {
                                    cartActions.fetchTaxRate(cart.id).then((data) => {
                                      setSubmitting(false);
                                      console.log('Tax data:');
                                      console.log(JSON.stringify(data));
                                      // INFO the rate is linked to the cart through PullState
                                      setEditBillingAddress(false);
                                      setActiveStep(3);
                                      cart.tax_price = data.price;
                                      console.log(cart);
                                      ShopStore.update((s) => {
                                        s.cart = cart;
                                      });
                                    }).catch((err) => {
                                      setSubmitting(false);
                                      console.error(err);
                                    });
                                  }).catch((err) => {
                                    setSubmitting(false);
                                    console.error(err);
                                  });
                                }
                              }}
                              style={{
                                marginTop: 30,
                                marginBottom: 0,
                              }}
                            >
                              <span className="snipcart-cart-button__grid">
                                <span className="snipcart-cart-button__content">
                                  {submitting
                                  && <i className="fa fa-spin fa-circle-notch" style={{ marginRight: 10 }} />}
                                  {'Continue to payment'}
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      )}

                      {activeStep !== 2 && fullShippingName && defaultShippingAddress && Object.keys(defaultShippingAddress).length > 0 && (
                        <div>
                          <div style={{ whiteSpace: 'pre-line' }}>
                            {`
                            ${fullShippingName}
                            ${shippingAddress1}${(shippingAddress2?.length) ? ` ${shippingAddress2}` : ''}, ${shippingCity}, ${shippingState}, ${shippingCountry} ${shippingZip}
                          `}
                          </div>
                          <div>
                            {
                              selectedRate.name
                                ? (
                                  <p
                                    style={{
                                      fontWeight: 600,
                                      marginTop: 8,
                                    }}
                                  >
                                    {`Shipping method: ${selectedRate.name} - $${selectedRate.price}`}
                                  </p>
                                ) : 'No rate selected'
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>

                <div className="snipcart-checkout-step">
                  <div className="snipcart-form">
                    <div
                      className={`snipcart__box ${activeStep !== 3 ? 'snipcart__box--gray snipcart__box--slim' : ''}`}
                    >
                      <div className="snipcart__box--header">
                        <div className="snipcart__box--title">
                          <div
                            className={`snipcart__box--badge snipcart__box--badge snipcart__font--bold snipcart__font--secondary ${activeStep === 3 ? 'snipcart__box--badge-highlight' : ''}`}
                          >
                            {'3'}
                          </div>
                          <h1 className="snipcart__font--subtitle">
                            {'Payment'}
                          </h1>
                        </div>
                      </div>

                      {activeStep === 3 && (
                        <div>
                          <div>
                            <table style={{ width: '100%' }}>
                              <tr>
                                <td align="left">Subtotal</td>
                                <td align="right">{cart.subtotal_price ? `$${cart.subtotal_price.toFixed(2)}` : 'Calculating...'}</td>
                              </tr>
                              <tr>
                                <td align="left">Taxes</td>
                                <td align="right">{`$${(cart.tax_price || 0).toFixed(2)}`}</td>
                              </tr>
                              <tr>
                                <td align="left">Shipping</td>
                                <td
                                  align="right"
                                >
                                  {`$${(cart.shipping_price || selectedRate.price || 0).toFixed(2)}`}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 16,
                                  }}
                                >
                                  {'Total'}
                                </td>
                                <td
                                  align="right"
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 16,
                                    color: '#ef5164',
                                  }}
                                >
                                  {cart.total_price ? `$${cart.total_price.toFixed(2)}` : 'Calculating...'}
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="snipcart-payment__form-container">
                            <div className="snipcart-payment-card-form__container">
                              <fieldset className="snipcart-form__set">
                                <div className="snipcart-form__field" style={{ padding: 0 }}>
                                  {renderPaymentMethods()}
                                </div>
                              </fieldset>
                            </div>
                          </div>
                          {activeStep === 3 && (
                            <div>{renderOrderButton()}</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                maxWidth: 600,
                position: isMobile ? 'relative' : 'sticky',
                top: isMobile ? 'unset' : 120,
                flex: 1,
                marginLeft: isMobile ? 0 : 30,
              }}
            >
              <div className="snipcart-form">
                <div className="snipcart__box">
                  {Object.keys(fulfillments).length > 0 && Object.keys(fulfillments).map((venueId, index) => (
                    <>
                      {Object.keys(fulfillments).length > 1 && (
                        <h4 style={{ marginBottom: 4 }}>
                          {'Shipment '}
                          {index + 1}
                          {' '}
                          {'of'}
                          {Object.keys(fulfillments).length}
                        </h4>
                      )}
                      <div style={{ textAlign: 'center' }}>
                        {'Sold by'}
                        {' '}
                        <span
                          style={{ fontWeight: 700 }}
                        >
                          {fulfillments[venueId].venue ? fulfillments[venueId].venue.name : 'Sigaro Smoke Shop'}
                        </span>
                        {', a licensed cigar reseller.'}
                      </div>
                      <List>
                        {fulfillments[venueId].products.map((product) => (
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Badge
                                overlap="circular"
                                color="primary"
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                badgeContent={product.quantity}
                              >
                                <Avatar alt={product.name} src={Product.getImage(product)} />
                              </Badge>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<span style={{ fontWeight: 700 }}>{Product.title(product)}</span>}
                              secondary={Product.packageTitle(product)}
                            />
                            <span style={{ fontWeight: 700 }}>{Product.formatPrice(product)}</span>
                          </ListItem>
                        ))}
                      </List>
                      <hr />
                    </>
                  ))}
                  <div>
                    <table style={{ width: '100%' }}>
                      <tr>
                        <td align="left">Subtotal</td>
                        <td
                          align="right"
                        >
                          {cart.subtotal_price ? `$${cart.subtotal_price.toFixed(2)}` : 'Calculating subtotal...'}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">Taxes</td>
                        <td align="right">{cart.tax_price ? `$${(cart.tax_price || 0).toFixed(2)}` : '-'}</td>
                      </tr>
                      <tr>
                        <td align="left">Shipping</td>
                        <td
                          align="right"
                        >
                          {cart.shipping_price ? `$${(cart.shipping_price || selectedRate.price || 0).toFixed(2)}` : 'Calculated in next step'}
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="left"
                          style={{
                            fontWeight: 700,
                            fontSize: 22,
                          }}
                        >
                          {'Total'}
                        </td>
                        <td
                          align="right"
                          style={{
                            fontWeight: 700,
                            fontSize: 22,
                          }}
                        >
                          {cart.total_price ? `$${cart.total_price.toFixed(2)}` : 'Calculating total...'}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div style={{ margin: 10 }}>
                <div
                  style={{
                    margin: 20,
                    textAlign: 'center',
                  }}
                >
                  <p
                    style={{
                      fontSize: 11,
                      marginBottom: 10,
                    }}
                  >
                    {'By placing your order, you agree to Boxpressd\'s '}
                    <a href="https://boxpressd.com/legal/privacy">Privacy Policy</a>
                    {' and '}
                    <a href="https://boxpressd.com/legal/sale">Terms of Sale</a>
                    {'.'}
                  </p>
                  <p style={{ fontSize: 11 }}>
                    {'Boxpressd and its affiliates do not sell to anyone under 21 years of age or the minimum legal age in their jurisdiction, whichever is higher.'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {processingOrder && <LoadingIndicator overlay />}
    </PageWrap>
  );
}

export default withRouter(Checkout);
